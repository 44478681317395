import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';

export const emailValidationCode = async (licenseRequest) => {
	if (isNil(licenseRequest)) return;
	try {
		const {
			data: { EmailValidationCode }
		} = await apollo.mutate({
			mutation: gql`
				mutation EMAIL_VALIDATION_CODE($newLicenseRequest: LicenseRequestInput!) {
					EmailValidationCode(NewLicenseRequest: $newLicenseRequest) {
						id
						createdAt
						updatedAt
						email
						phone
						password
						licenseOwner
						projectTitle
						xid
						validated
						validatedOn
						language
						userId
						timeZone
						requestType
						userName
						userFirstName
						userTypeForFilm
						nextProductionDate
						budgetBracket
						emailValidationCode
						emailValidationProcess
					}
				}
			`,
			variables: {
				newLicenseRequest: licenseRequest
			},
			fetchPolicy: 'no-cache'
		});

		return {
			...EmailValidationCode,
			id: parseInt(EmailValidationCode.id)
		};
	} catch (e) {
		console.log({ e });
	}
};

export const phoneValidationCode = async (licenseRequest) => {
	if (isNil(licenseRequest)) return;
	try {
		const {
			data: { PhoneValidationCode }
		} = await apollo.mutate({
			mutation: gql`
				mutation PHONE_CODE_VALIDATION($newLicenseRequest: LicenseRequestInput!) {
					PhoneValidationCode(NewLicenseRequest: $newLicenseRequest) {
						id
						createdAt
						updatedAt
						email
						phone
						password
						licenseOwner
						projectTitle
						xid
						validated
						validatedOn
						language
						userId
						timeZone
						requestType
						userName
						userFirstName
						userTypeForFilm
						nextProductionDate
						budgetBracket
						emailValidationCode
						phoneValidationCode
						userTypeDesc
						languageDesc
					}
				}
			`,
			variables: {
				newLicenseRequest: licenseRequest
			},
			fetchPolicy: 'no-cache'
		});

		return {
			...PhoneValidationCode,
			id: parseInt(PhoneValidationCode.id)
		};
	} catch (e) {
		console.log({ e });
	}
};

export const signinService = async (email, password, xid = '', urXid = '', createProject = false) => {
	const mutationGql = gql`
		mutation ($email: String!, $password: String!, $xid: String, $urXid: String, $createProject: Boolean) {
			signIn(email: $email, password: $password, xid: $xid, UrXid: $urXid, CreateProject: $createProject)
		}
	`;
	return await apollo.mutate({
		mutation: mutationGql,
		variables: {
			email: email,
			password: password,
			xid: xid,
			urXid: urXid,
			createProject: createProject
		}
	});
};
