<template>
	<div>
		<b-row class="mb-3">
			<b-col>
				<b-alert class="mt-3" variant="warning" :show="showAlertSuccess">
					{{ FormMSG(60, 'An e-mail containing a validation code has just been sent to') + ' ' + emailValue }}
				</b-alert>
				<b-alert class="mt-3" variant="error" :show="showAlertError">
					{{ msgModalError }}
				</b-alert>
			</b-col>
		</b-row>
		<b-row v-if="hiddenInput && loadingFor.verifyEmail">
			<b-col class="text-center"> <b-spinner label="Loading..." small /> {{ FormMSG(146, 'Send code validation') }} </b-col>
		</b-row>
		<div class="password-forgotten-form">
			<b-row v-if="!hiddenInput" class="mb-3">
				<b-col :cols="$screen.width <= 576 ? 12 : 8">
					<b-input-group class="mb-3">
						<b-input-group-prepend>
							<b-input-group-text>
								<component :is="getLucideIcon('User')" :color="ICONS.PERCENT.color" :size="18" />
							</b-input-group-text>
						</b-input-group-prepend>
						<b-form-input
							v-model="email"
							type="email"
							class="form-control"
							:placeholder="FormMSG(103, 'Email address')"
							:state="stateError.email"
							autocomplete="username email"
							aria-describedby="input-email-feedback"
							@input="handleInputEmail"
							style="border-radius: 0px 8px 8px 0px !important"
						/>
						<b-form-invalid-feedback id="input-email-feedback">
							<span v-if="forResetPasswordUser">
								{{ FormMSG(169, 'The mail is not yet registered as a user in the application') }}
							</span>
						</b-form-invalid-feedback>
					</b-input-group>
				</b-col>
				<b-col :cols="$screen.width <= 576 ? 12 : 4">
					<b-button variant="primary" block :disabled="disableVerifyEmail || loadingFor.verifyEmail" @click="verifyEmail">
						<span v-if="dataToCheck.emailCodeValidation !== 0 && !loadingFor.verifyEmail">
							{{ FormMSG(45, 'Resend code') }}
						</span>
						<span v-if="!loadingFor.verifyEmail && dataToCheck.emailCodeValidation === 0">
							{{ FormMSG(46, 'Verify email') }}
						</span>
						<span v-if="loadingFor.verifyEmail">
							{{ FormMSG(47, 'Check email') }}
						</span>
					</b-button>
				</b-col>
			</b-row>
			<p v-if="$screen.width <= 576 && showVerificationMail">
				{{ FormMSG(53, 'Enter email verfication code') }}
			</p>
			<b-row v-if="showVerificationMail" class="mb-3">
				<b-col cols="8">
					<fieldset class="form-group" :horizontal="true" id="fieldset-email-validation" aria-labelledby="aria-phone-validation">
						<div class="form-row">
							<legend v-if="$screen.width >= 471" tabindex="-1" class="col-8 bv-no-focus-ring col-form-label" id="aria-email-validation">
								{{ FormMSG(53, 'Enter email verfication code') }} :
							</legend>
							<div class="col">
								<b-form-input
									v-model="emailCodeValidation"
									:state="stateError.emailCodeValidation"
									placeholder="ex: 123456"
									@input="handleInputEmailCodeValidation"
								/>
							</div>
						</div>
					</fieldset>
				</b-col>
				<b-col v-if="stateError.emailCodeValidation !== null && stateError.emailCodeValidation === false" cols="4" class="pt-2">
					<X color="#B11548" :size="20" />
				</b-col>
				<b-col v-if="stateError.emailCodeValidation === true" cols="4" :class="$screen.width <= 576 ? 'pt-2' : 'pt-2'">
					<Check color="#28a745" :size="20" />
				</b-col>
			</b-row>
		</div>
		<b-modal
			header-class="header-class-modal-doc-package"
			v-model="showModalSuccess"
			:title="FormMSG(62, 'Validation code')"
			ok-only
			ok-variant="success"
			:ok-title="FormMSG(61, 'Ok')"
			no-close-on-esc
			no-close-on-backdrop
			modal-class="mui-animation"
			:fade="false"
		>
			{{ FormMSG(60, 'An e-mail containing a validation code has just been sent to') + ' ' + email }}
		</b-modal>
		<b-modal
			header-class="header-class-modal-doc-package"
			v-model="showModalError"
			:title="FormMSG(125, 'Error')"
			ok-only
			ok-variant="danger"
			:ok-title="FormMSG(126, 'Ok')"
			no-close-on-backdrop
			no-close-on-esc
			header-text-variant="danger"
			body-text-variant="danger"
			modal-class="mui-animation"
			:fade="false"
		>
			{{ msgModalError }}
		</b-modal>
	</div>
</template>

<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { emailValidationCode } from '@/cruds/registration.crud';
import { X, Check } from 'lucide-vue';
import gql from 'graphql-tag';
import { store } from '@/store';
import { getNavigatorLanguageValue } from '@/cruds/language.crud';

export default {
	name: 'EmailValidation',
	components: {
		X,
		Check
	},
	mixins: [LanguageMessages, GlobalMixin],
	props: {
		emailValue: { type: String, default: '' },
		forResetPasswordUser: { type: Boolean, default: false },
		forCreateUser: { type: Boolean, default: false },
		useAlert: { type: Boolean, default: false },
		fromLogin: { type: Boolean, default: false }
	},
	data() {
		return {
			email: '',
			emailCodeValidation: '',
			loadingFor: {
				verifyEmail: false
			},
			dataToCheck: {
				email: '',
				emailCodeValidation: 0
			},
			stateError: {
				email: null,
				emailCodeValidation: null
			},
			hiddenInput: false,
			showModalSuccess: false,
			showModalError: false,
			showAlertSuccess: false,
			showAlertError: false,
			msgModalError: '',
			userXid: ''
		};
	},
	computed: {
		showVerificationMail() {
			if (this.dataToCheck.emailCodeValidation !== 0) {
				return true;
			}
			return false;
		},
		disableVerifyEmail() {
			const emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
			if (!emailfilter.test(this.email)) {
				return true;
			}
			return false;
		}
	},
	watch: {
		emailValue: {
			handler(val) {
				if (val !== '') {
					this.hiddenInput = true;
				} else {
					this.hiddenInput = false;
				}
			},
			immediate: true,
			deep: true
		}
	},
	async mounted() {
		if (this.emailValue) {
			this.email = this.emailValue;
			this.dataToCheck.email = this.emailValue;
			this.stateError.email = null;
			this.stateError.emailCodeValidation = null;
			await this.verifyEmail();
		}
	},
	methods: {
		handleInputEmail(val) {
			this.email = val;
			this.emitEvent();
		},
		getLanguuage() {
			if (this.fromLogin) {
				return getNavigatorLanguageValue(navigator.language) === 1 ? 1 : 0;
			} else {
				return store.appLanguage();
			}
		},
		async verifyEmail() {
			try {
				this.loadingFor.verifyEmail = true;
				const email = this.email;

				if (this.forResetPasswordUser) {
					const res = await this.verifyEmailForResetPassword();
					this.userXid = res;
					this.stateError.email = true;
				}

				const result = await emailValidationCode({
					email: this.email,
					language: this.getLanguuage()
				});

				this.dataToCheck.emailCodeValidation = result.emailValidationCode;
				this.dataToCheck.email = email;

				if (!this.useAlert) {
					this.showModalSuccess = true;
				} else {
					this.showAlertSuccess = true;
				}

				this.emailCodeValidation = '';
				this.stateError.emailCodeValidation = null;
				this.loadingFor.verifyEmail = false;
			} catch (error) {
				if (error.mailNotExist && error.mailNotExist === true) {
					this.stateError.email = false;
					this.msgModalError = this.FormMSG(169, 'The mail is not yet registered as a user in the application');

					if (!this.useAlert) {
						this.showModalError = true;
					} else {
						this.showAlertError = true;
					}

					this.initDataToCheck();
				}
				this.loadingFor.verifyEmail = false;
			}
		},
		initDataToCheck() {
			this.dataToCheck = {
				email: '',
				emailCodeValidation: 0
			};
		},
		async verifyEmailForResetPassword() {
			const mutation = gql`
				mutation RESET_PASSWORD_REQUEST($email: String!) {
					ResetPasswordRequest(NewLicenseRequest: { email: $email }) {
						email
						xid
					}
				}
			`;
			const myPromise = new Promise((resolve, reject) => {
				this.$apollo
					.mutate({
						mutation,
						variables: {
							email: this.email
						}
					})
					.then((result) => {
						resolve(result.data.ResetPasswordRequest.xid);
					})
					.catch((error) => {
						reject({
							mailNotExist: true
						});
					});
			});

			return myPromise;
		},
		handleInputEmailCodeValidation(value) {
			if (parseInt(this.dataToCheck.emailCodeValidation) === parseInt(value)) {
				this.stateError.emailCodeValidation = true;
			} else {
				this.stateError.emailCodeValidation = false;
			}

			this.emitEvent();
		},
		emitEvent() {
			if (parseInt(this.dataToCheck.emailCodeValidation) === parseInt(this.emailCodeValidation) && this.dataToCheck.email === this.email) {
				this.$emit('verification-email:check', {
					emailVerified: true,
					email: this.dataToCheck.email,
					xid: this.userXid
				});
			} else {
				this.$emit('verification-email:check', {
					emailVerified: false,
					email: this.dataToCheck.email,
					xid: this.userXid
				});
			}
		}
	}
};
</script>

<style scoped></style>
